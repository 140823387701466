




































































































































  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import { Action, State as StateClass } from 'vuex-class';
  import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
  import { ValidationObserver } from 'vee-validate';
  import { State } from '@/models/State';
  import { DataContainerStatus } from '@/models/Common';
  import Modal from '@/components/common/Modal.vue';
  import FormInput, { FormIcons } from '@/components/common/form-elements/FormInput.vue';
  import FormSelect from '@/components/common/form-elements/FormSelect.vue';
  import FormDatePicker from '@/components/common/form-elements/FormDatePicker.vue';
  import BigNumber from 'bignumber.js';
  import to from 'await-to-js';
  import { bloqifyFunctions } from '@/boot/firebase';
  import { Asset } from '@/models/assets/Asset';
  import { AddEarningParam, EarningActionParam } from '@/store/modules/earning';
  import { AssetEarning } from '@/models/assets/Earnings';

interface SelectOptions {
  value: string | number;
  text: string;
}

interface GetFinancialsInfoOutput {
  id: string;
  investorName: string;
  currentInvestment: number;
  currentShares: number;
}

@Component({
  components: {
    Modal,
    FormDatePicker,
    FormInput,
    FormSelect,
    ValidationObserver,
  },
})
  export default class EarningModal extends Vue {
  @Prop() earning?: AssetEarning;
  @Prop() assetId!: string;
  @Prop() mode!: 'add' | 'delete';

  @Action(addToastMessage) addToastMessage!: (payload: { text: string; type: string }) => void;
  @Action deleteEarning!: ({ assetId, earningId }: EarningActionParam) => Promise<void>;
  @Action addEarning!: (data: AddEarningParam) => void;

  @StateClass('boundAsset') boundAsset!: Asset;
  @StateClass('earning') stateEarning!: State['earning'];

  amount: number | string = 0;
  dividendFormat: string | number | null = null;
  date: Date = new Date();
  description = '';
  paymentDate: Date = new Date();
  investorInputFields: { id: string; amount: number | string }[] = [];
  allInvestors = true;
  investorsData: GetFinancialsInfoOutput[] = [];

  procesing = false;
  FormIcons = FormIcons;

  async mounted(): Promise<void> {
    this.procesing = true;
    this.dividendFormat = this.dividendsFormatOptions[0].value;
    const [getFinancialsInfoError, financialsInfo] = await to(
      bloqifyFunctions.httpsCallable('getFinancialsInfo')({ assetId: this.assetId, divFormat: this.dividendFormat }),
    );
    if (getFinancialsInfoError) {
      this.addToastMessage({
        text: getFinancialsInfoError.message,
        type: 'danger',
      });
      return;
    }
    this.investorsData = financialsInfo?.data as GetFinancialsInfoOutput[];
    // Initialize investorInputFields based on currentInvestment values
    this.investorInputFields = this.investorsData.map((investor) => ({
      id: investor.id,
      amount: 0,
    }));
    this.procesing = false;
  }

  @Watch('amount')
  @Watch('allInvestors')
  onAmountChange(): void {
    const newValue = Number(this.amount);
    const newValueDp = new BigNumber(this.amount).dp(6, BigNumber.ROUND_DOWN).toNumber() || 0;
    if (!isNaN(Number(this.amount))) {
      if (newValue !== newValueDp) {
        this.amount = newValueDp;
      }
    }

    if (this.allInvestors) {
      this.calculateInvestorInputFields();
    }
  }

  @Watch('stateEarning.status')
  onCreateEarningStatusChange(newStatus: DataContainerStatus): void {
    if (newStatus === DataContainerStatus.Success) {
      this.addToastMessage({
        text: 'Operation completed successfully',
        type: 'success',
      });
    } else if (newStatus === DataContainerStatus.Error) {
      this.addToastMessage({
        text: this.stateEarning!.error?.message || 'There was a problem with operation',
        type: 'danger',
      });
    }
    // Close modal
    if (newStatus !== DataContainerStatus.Processing) {
      this.$emit('close');
    }
  }

  get getTotalAmount(): number {
    return this.investorsData
      .reduce((total, investor) => total.plus(investor.currentInvestment), new BigNumber(0))
      .toNumber();
  }

  get getTotalShares(): number {
    return this.investorsData
      .reduce((total, investor) => total.plus(investor.currentShares), new BigNumber(0))
      .toNumber();
  }

  get investorInputFieldsAmount(): number {
    return this.investorInputFields
      .reduce((total, input) => total.plus(Number(input.amount)), new BigNumber(0))
      .toNumber();
  }

  get isProcessing(): boolean {
    return this.stateEarning?.status === DataContainerStatus.Processing;
  }

  get dividendsFormatOptions(): SelectOptions[] {
    return this.boundAsset.dividendsFormat.map(
      (row): SelectOptions => ({
        value: row.contents[1],
        text: `${row.contents[1]} %`,
      }),
    );
  }

  onEarningInput(index: number, value: string): void {
    const newValue = Number(value);
    const newValueDp = new BigNumber(value).dp(6, BigNumber.ROUND_DOWN).toNumber() || 0;

    if (!isNaN(Number(value))) {
      if (newValue !== newValueDp) {
        this.investorInputFields[index].amount = newValueDp;
      } else {
        this.investorInputFields[index].amount = value;
      }
    }

    this.amount = this.investorInputFieldsAmount;
  }

  calculateInvestorInputFields(): void {
    if (!this.allInvestors) {
      return;
    }

    const totalShares = this.getTotalShares;
    this.investorInputFields = this.investorsData.map((investor) => {
      const amount = new BigNumber(investor.currentShares)
          .dividedBy(totalShares)
          .times(this.amount)
          .dp(6, BigNumber.ROUND_HALF_DOWN)
          .toNumber() || 0;
      return {
        id: investor.id,
        amount,
      };
    });

    // If the total amount is different that the sum of all individuals amount we need to add the difference to one of the investors
    const difference = new BigNumber(this.amount).minus(this.investorInputFieldsAmount).toNumber();
    if (difference) {
      const newAmount = new BigNumber(this.investorInputFields[0].amount).plus(difference).toNumber();
      this.investorInputFields[0].amount = newAmount;
    }
  }

  onAddEarning(): void {
    this.addEarning({
      assetId: this.assetId,
      date: this.date,
      paymentDate: this.paymentDate,
      amount: Number(this.amount),
      dividendFormat: Number(this.dividendFormat),
      description: this.description,
      earningAmounts: this.investorInputFields.reduce(
        (acum, { id, amount }) => {
          if (Number(amount) !== 0) {
            acum.push({
              investmentId: id,
              amount: Number(amount),
            });
          }
          return acum;
        },
        [] as { investmentId: string; amount: number }[],
      ),
    });
  }

  onDeleteEarning(): void {
    // eslint-disable-next-line no-void
    void this.deleteEarning({
      earningId: this.earning!.id!,
      assetId: this.assetId,
    });
  }
  }
