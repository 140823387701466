













































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { Action, State as StateClass } from 'vuex-class';
import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
import { ValidationObserver } from 'vee-validate';
import { bloqifyFirestore } from '@/boot/firebase';
import { State } from '@/models/State';
import { Asset } from '@/models/assets/Asset';
import { Dividend } from '@/models/assets/Dividends';
import { DataContainerStatus } from '@/models/Common';
import FormInput, { FormIcons } from '@/components/common/form-elements/FormInput.vue';
import FormDatePicker from '@/components/common/form-elements/FormDatePicker.vue';
import ModifyDividendModal from '@/components/assets/ModifyDividendModal.vue';
import Earnings from '@/components/assets/assetFinancials/Earnings.vue';

@Component({
  components: {
    ModifyDividendModal,
    FormDatePicker,
    FormInput,
    ValidationObserver,
    Earnings,
  },
})
export default class AssetFinancials extends Vue {
  @Action getAssetById!: ({ id }: { id: string }) => void;
  @Action addDividend!: ({ assetId, amount, paymentDateTime }: { assetId: string, amount: number, paymentDateTime: Date }) => void;
  @Action(addToastMessage) addToastMessage!: Function;
  @Action bindFirestoreReferences!: Function;
  @Action unbindFirestoreReferences!: Function;
  @StateClass('boundAsset') asset!: Asset;
  @StateClass('boundDividends') boundDividends!: Dividend[];
  @StateClass('asset') stateAsset!: State['asset'];
  @StateClass('dividend') stateDividend!: State['dividend'];

  // Dividend fields
  amount: number | null = null;
  paymentDateTime: Date | null = new Date();
  loadingPromise: Promise<any> = Promise.resolve();
  showModifyDividendModal: boolean = false;
  selectedDividend: Dividend | null = null;
  FormIcons = FormIcons;
  dividendOptions = {
    headings: {
      period: 'Period',
      amount: 'Earning amount',
      modify: '',
    },
    filterable: ['period', 'amount'],
    columnsClasses: {
      period: 'list__filter--name align-middle',
      amount: 'list__filter--sold align-middle',
      modify: 'list__filter--modify align-middle',
    },
    skin: 'table table-sm table-nowrap card-table', // This will add CSS classes to the main table
  };

  @Watch('stateDividend.status')
  onNewDividendError(newStatus: DataContainerStatus): void {
    if (newStatus === DataContainerStatus.Success) {
      this.addToastMessage({
        text: `Dividend successfully ${this.stateDividend!.operation === 'addDividend' ? 'added' : 'modified'}`,
        type: 'success',
      });
    } else if (newStatus === DataContainerStatus.Error) {
      this.addToastMessage({
        text: this.stateDividend!.error || 'There was a problem with adding/updating dividends',
        type: 'danger',
      });
    }
  }

  @Watch('assetId', { immediate: true })
  async onNewAsset(newAssetId: string | null): Promise<void> {
    if (newAssetId) {
      this.loadingPromise = this.bindFirestoreReferences([
        {
          name: 'boundAsset',
          ref: bloqifyFirestore.collection('assets').doc(newAssetId),
        },
        {
          name: 'boundDividends',
          ref: bloqifyFirestore.collection('assets').doc(newAssetId).collection('dividends')
            .orderBy('paymentDateTime', 'desc'),
        },
        {
          name: 'boundEarnings',
          ref: bloqifyFirestore
            .collection(`assets/${newAssetId}/assetEarnings`)
            .where('deleted', '==', false)
            .orderBy('earningDateTime', 'desc'),
        },
      ]);
    }
  }

  @Watch('paymentDateTime', { immediate: true })
  async onPaymentDateTimeChange(): Promise<void> {
    if (this.paymentDateTime) {
      this.paymentDateTime.setMonth(11);
    }
  }

  beforeDestroy(): void {
    this.unbindFirestoreReferences([
    { name: 'boundDividends' },
    { name: 'boundEarnings' },
    ]);
  }

  get assetId(): string {
    return this.$route.params.assetId;
  }

  get defaultDate(): Date {
    const today = new Date();
    today.setMonth(today.getMonth() - 1);
    return today;
  }

  get dividends(): Dividend[] {
    return this.boundDividends.map((dividend): Dividend => ({ ...dividend, id: dividend.id }));
  }

  openDividendModal(dividend: Dividend): void {
    this.showModifyDividendModal = true;
    this.selectedDividend = dividend;
  }

  async submitDividends(): Promise<void> {
    this.addDividend({ assetId: this.assetId, amount: Number(this.amount), paymentDateTime: this.paymentDateTime as Date });
  }
}
